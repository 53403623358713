import React from 'react';

class KakaoTalk extends React.Component {
    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        location.href="https://open.kakao.com/o/scgbmtZb";
    }
    render() {
        return (
            "Loading...🥳"
           );
    }
  }

  export default KakaoTalk;